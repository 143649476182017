// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-date-time-range-picker {
  display: flex;
  flex-direction: column;
  z-index: 1;
  cursor: default;
}

  .r-date-time-range-picker__text-input {
    height: 19px;
    width: calc(100% - 16px);
  }

  .r-date-time-range-picker__date-time-picker-dd-wrap {
    position: relative;
  }

  .r-date-time-range-picker__date-time-picker-dd {
    position: absolute;
    background: white;
    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);
  }

  .r-date-time-range-picker__date-time-picker-dd-range {
      padding: 8px;
    }

  .r-date-time-range-picker__date-time-picker-dd-pickers {
      display: flex;
    }

  .r-date-time-range-picker__date-time-picker-dd-pickers > * {
        margin-left: 8px;
      }

  .r-date-time-range-picker__date-time-picker-dd-pickers > *:first-child {
          margin-left: 0;
        }

  .r-date-time-range-picker__date-time-picker-dd-pickers .rdtPicker {
        box-shadow: 0;
        border-bottom: 0;
      }

  .r-date-time-range-picker__date-time-picker-dd-pickers-left > .rdtPicker {
          border-left: 0;
        }

  .r-date-time-range-picker__date-time-picker-dd-pickers-right > .rdtPicker {
          border-right: 0;
        }

  .r-date-time-range-picker__date-time-picker-dd-pickers-picker {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
`, "",{"version":3,"sources":["webpack://./app/components/DateTimeRangePicker/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,eAAe;AAuDjB;;EArDE;IACE,YAAY;IACZ,wBAAwB;EAC1B;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,sCAAsC;EAwCxC;;EAtCE;MACE,YAAY;IACd;;EAEA;MACE,aAAa;IAgCf;;EA9BE;QACE,gBAAgB;MAKlB;;EAHE;UACE,cAAc;QAChB;;EAGF;QACE,aAAa;QACb,gBAAgB;MAClB;;EAGE;UACE,cAAc;QAChB;;EAIA;UACE,eAAe;QACjB;;EAGF;QACE,aAAa;QACb,sBAAsB;QACtB,mBAAmB;MACrB","sourcesContent":[".r-date-time-range-picker {\n  display: flex;\n  flex-direction: column;\n  z-index: 1;\n  cursor: default;\n\n  &__text-input {\n    height: 19px;\n    width: calc(100% - 16px);\n  }\n\n  &__date-time-picker-dd-wrap {\n    position: relative;\n  }\n\n  &__date-time-picker-dd {\n    position: absolute;\n    background: white;\n    box-shadow: 0 1px 3px rgb(0 0 0 / 10%);\n\n    &-range {\n      padding: 8px;\n    }\n\n    &-pickers {\n      display: flex;\n\n      & > * {\n        margin-left: 8px;\n\n        &:first-child {\n          margin-left: 0;\n        }\n      }\n\n      & .rdtPicker {\n        box-shadow: 0;\n        border-bottom: 0;\n      }\n\n      &-left {\n        & > .rdtPicker {\n          border-left: 0;\n        }\n      }\n\n      &-right {\n        & > .rdtPicker {\n          border-right: 0;\n        }\n      }\n\n      &-picker {\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
