// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-confirmation-dialog {
  display: flex;
  flex-direction: column;
  place-self: center center;
  align-items: flex-start;
  background-color: var(--color-grayscale-1);
  justify-content: space-between;
  border-radius: 3px;
  background-repeat: no-repeat;
  padding: 36px 50px;
  width: 456px;
  max-height: 450px;
  overflow: auto;
}

  .app-confirmation-dialog > * {
    margin-top: 16px;
  }

  .app-confirmation-dialog > *:first-child {
      margin-top: 0;
    }

  .app-confirmation-dialog__message {
    display: flex;
    flex-direction: column;
  }

  .app-confirmation-dialog__actions {
    display: flex;
    flex-grow: 1;
    align-self: flex-end;
    flex-direction: row;
    align-items: flex-end;
  }

  .app-confirmation-dialog__actions > * {
      margin-left: 8px;
    }

  .app-confirmation-dialog__actions > *:first-child {
        margin-left: 0;
      }
`, "",{"version":3,"sources":["webpack://./app/components/ConfirmationDialog/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,uBAAuB;EACvB,0CAA0C;EAC1C,8BAA8B;EAC9B,kBAAkB;EAClB,4BAA4B;EAC5B,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,cAAc;AA8BhB;;EA5BE;IACE,gBAAgB;EAKlB;;EAHE;MACE,aAAa;IACf;;EAGF;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,qBAAqB;EASvB;;EAPE;MACE,gBAAgB;IAKlB;;EAHE;QACE,cAAc;MAChB","sourcesContent":[".app-confirmation-dialog {\n  display: flex;\n  flex-direction: column;\n  place-self: center center;\n  align-items: flex-start;\n  background-color: var(--color-grayscale-1);\n  justify-content: space-between;\n  border-radius: 3px;\n  background-repeat: no-repeat;\n  padding: 36px 50px;\n  width: 456px;\n  max-height: 450px;\n  overflow: auto;\n\n  & > * {\n    margin-top: 16px;\n\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n\n  &__message {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__actions {\n    display: flex;\n    flex-grow: 1;\n    align-self: flex-end;\n    flex-direction: row;\n    align-items: flex-end;\n\n    & > * {\n      margin-left: 8px;\n\n      &:first-child {\n        margin-left: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
