import React from 'react';

function CompanyLogo(props) {
  return (
    <svg {...props} version="1.1" viewBox="0 0 31 34">
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-20 -11)" fill="#333">
          <path d="m31 25.213l11.091-6.2129 1.909 1.0675-10.684 5.9927v12.94l-2.3163-1.3103v-12.477zm5.0113-10.213l1.9887 1.15-11.024 6.2842v12.566l-1.9764-1.1433v-12.546l11.011-6.3111zm9.9887 10v7l-6-3.649 6-3.351zm-2 10.658l-6 3.3425v-7l6 3.6575zm-24-16.157v16.998l15.501 8.5013 15.499-8.5013v-16.998l-15.499-8.5008-15.501 8.5008z" />
        </g>
      </g>
    </svg>
  );
}

export default CompanyLogo;
