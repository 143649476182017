import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Checkbox from '../Checkbox';
import DateTimePicker from '../DateTimePicker';

import './style.css';

/**
 * @param {moment.MomentInput} value
 * @param {string} [timezone]
 * @returns {moment.Moment}
 */
const createMoment = (value, timezone) => {
  if (timezone) {
    return moment(value).tz(timezone);
  }

  return moment(value);
};

function DateTimeRangePicker(props) {
  const { showTimezones } = useFlags();
  const momentDateFormat = 'MM/DD/YYYY';
  let momentFormat = `${momentDateFormat} HH:mm${showTimezones ? ' z' : ''}`;

  if (props.timeFormat === false) {
    momentFormat = `${momentDateFormat}`;
  } else if (props.timeFormat) {
    momentFormat = `${momentDateFormat} ${props.timeFormat}`;
  }

  const momentStateStartValue = props.startValue
    ? createMoment(props.startValue, showTimezones ? props.timezone : undefined)
    : null;
  let momentStateEndValue = props.endValue
    ? createMoment(props.endValue, showTimezones ? props.timezone : undefined)
    : null;

  if (momentStateStartValue && !momentStateEndValue) {
    momentStateEndValue = createMoment(props.startValue, showTimezones ? props.timezone : undefined);
  }

  const [state, setState] = useState({
    startValue:
      momentStateStartValue && momentStateStartValue.isValid() ? momentStateStartValue.format(momentFormat) : null,
    endValue: momentStateEndValue && momentStateEndValue.isValid() ? momentStateEndValue.format(momentFormat) : null,
    rangeChecked: props.disableRange
      ? false
      : props.ranged ||
        props.alwaysRanged ||
        (momentStateEndValue && momentStateEndValue.isValid()) ||
        (momentStateEndValue &&
          momentStateEndValue.isValid() &&
          momentStateStartValue &&
          momentStateStartValue.isValid() &&
          !momentStateStartValue.isSame(momentStateEndValue))
  });

  let className = 'r-date-time-range-picker';

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleRangeToggle = () => {
    if (!props.alwaysRanged) {
      setState((prevState) => {
        const checked = !prevState.rangeChecked;

        const newState = {
          ...prevState,
          endValue: checked ? prevState.endValue : prevState.startValue,
          rangeChecked: checked
        };

        if (!checked) {
          if (props.onChange) {
            props.onChange(newState.startValue, newState.endValue);
          }
        }

        return newState;
      });
    }
  };

  const handleDatePicker1Change = useCallback((momentValue) => {
    if (momentValue !== state.startValue && momentValue !== props.startValue) {
      setState((prevState) => {
        const momentStartValue = createMoment(momentValue, showTimezones ? props.timezone : undefined);
        const momentPrevEndValue = createMoment(prevState.endValue, showTimezones ? props.timezone : undefined);

        const newState = {
          ...prevState,
          startValue: momentStartValue.isValid() ? momentStartValue.format(momentFormat) : null,
          endValue: null
        };

        if (prevState.rangeChecked) {
          if (momentStartValue.isAfter(momentPrevEndValue)) {
            newState.endValue = newState.startValue;
          } else {
            newState.endValue = prevState.endValue || newState.startValue;
          }
        } else {
          newState.endValue = newState.startValue;
        }

        if (props.onChange) {
          props.onChange(newState.startValue, newState.endValue);
        }

        return newState;
      });
    }
  }, []);

  const handleDatePicker2Change = useCallback((momentValue) => {
    if (momentValue !== state.endValue && momentValue !== props.endValue) {
      setState((prevState) => {
        const momentStartValue = createMoment(prevState.startValue, showTimezones ? props.timezone : undefined);
        const momentEndValue = createMoment(momentValue, showTimezones ? props.timezone : undefined);

        const newState = {
          ...prevState,
          endValue: null
        };

        if (!props.alwaysRanged && !momentValue) {
          newState.rangeChecked = false;
        }

        if (momentEndValue.isValid()) {
          newState.endValue = momentEndValue.format(momentFormat);

          if (momentStartValue.isAfter(momentEndValue)) {
            newState.startValue = newState.endValue;
          }
        }

        if (props.onChange) {
          props.onChange(newState.startValue, newState.endValue);
        }

        return newState;
      });
    }
  }, []);

  return (
    <div className={className}>
      <div className="r-date-time-range-picker__date-time-picker-dd-wrap">
        <div className="r-date-time-range-picker__date-time-picker-dd">
          {!props.hideRange && !props.disableRange && (
            <div className="r-date-time-range-picker__date-time-picker-dd-range">
              <Checkbox
                alwaysChecked={props.alwaysRanged}
                checked={state.rangeChecked}
                label="Range"
                onToggle={handleRangeToggle}
              />
            </div>
          )}
          <div className="r-date-time-range-picker__date-time-picker-dd-pickers">
            <div className="r-date-time-range-picker__date-time-picker-dd-pickers-picker">
              <DateTimePicker
                className="r-date-time-range-picker__date-time-picker-dd-pickers-left"
                open
                dateFormat="MM/DD/YYYY"
                {...props.inputProps}
                value={state.startValue}
                onChange={handleDatePicker1Change}
                timezone={props.timezone}
              />
            </div>
            {state.rangeChecked && (
              <div className="r-date-time-range-picker__date-time-picker-dd-pickers-picker">
                <DateTimePicker
                  className="r-date-time-range-picker__date-time-picker-dd-pickers-right"
                  open
                  dateFormat={props.singleDay ? false : 'MM/DD/YYYY'}
                  {...props.inputProps}
                  value={state.endValue}
                  onChange={handleDatePicker2Change}
                  timezone={props.timezone}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

DateTimeRangePicker.propTypes = {
  hideRange: PropTypes.bool,
  disableRange: PropTypes.bool,
  className: PropTypes.string,
  startValue: PropTypes.any,
  endValue: PropTypes.any,
  alwaysRanged: PropTypes.bool,
  ranged: PropTypes.bool,
  timeFormat: PropTypes.object,
  singleDay: PropTypes.bool,
  onChange: PropTypes.func,
  timezone: PropTypes.string.isRequired,
  inputProps: PropTypes.object
};

export default React.memo(DateTimeRangePicker);
