import React from 'react';
import PropTypes from 'prop-types';
import CopyIcon from '@components/deprecatedTookit/icons/fa/regular/CopyIcon';
import copy from 'clipboard-copy';
import IconButton from '../IconButton';
import Toaster, { raiseToast } from '../Toaster/component';
import SystemToast from '../SystemToast/component';

import './style.css';

function CopyButton(props) {
  const baseClassName = 'CopyButton';
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleClick = async (e) => {
    e.stopPropagation();
    copy(props.value);
    raiseToast(<SystemToast type={SystemToast.Type.SUCCESS} message="Copied to clipboard" />, {
      position: Toaster.Position.BOTTOM_LEFT
    });
  };

  const handleHover = async (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <IconButton
      className={className}
      icon={<CopyIcon data-tooltip-id="copy-icon" />}
      onClick={props.onClick || handleClick}
      onHover={handleHover}
    />
  );
}

CopyButton.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func
};

export default CopyButton;
