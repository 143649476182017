import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateTime from 'react-datetime';
import { useFlags } from 'launchdarkly-react-client-sdk';

import TextInput from '../TextInput';

import './style.css';

function DateTimePicker(props) {
  const { showTimezones } = useFlags();

  const momentDateFormat = 'MM/DD/YYYY';
  let momentTimeFormat = `HH:mm${showTimezones ? ' z' : ''}`;
  let momentFormat = `${momentDateFormat} ${momentTimeFormat}`;

  if (props.timeFormat === false) {
    momentTimeFormat = false;
    momentFormat = `${momentDateFormat}`;
  }

  const [state, setState] = useState({
    inputValue:
      props.value || props.defaultValue
        ? moment(props.value || props.defaultValue)
            .tz(props.timezone)
            .format(momentFormat)
        : '',
    inputFocused: false
  });

  const prevPropsValue = useRef(state.inputValue);

  if (props.value && prevPropsValue.current !== props.value) {
    const dateTime = moment(props.value).tz(props.timezone).format(momentFormat);
    prevPropsValue.current = dateTime;
    state.inputValue = dateTime;
  }

  let className = 'rdt';

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleFocus = (event) => {
    setState((prevState) => ({
      ...prevState,
      inputFocused: true
    }));

    if (props.onFocus) {
      props.onFocus(event);
    }
  };

  const handleBlur = (event) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleChange = (value) => {
    const inputValueMoment = moment(value, momentFormat).tz(props.timezone);

    setState((prevState) => ({
      ...prevState,
      inputValue: inputValueMoment.format(momentFormat)
    }));

    if (props.onChange) {
      props.onChange(inputValueMoment, props);
    }
  };

  const handleInputChange = (value) => {
    const dateTimeChunks = value.split(' ');
    const dateChunk = dateTimeChunks[0];
    const dateChunks = dateChunk.split('/');
    const yearChunk = dateChunks[2];
    if (!value || (yearChunk && yearChunk.length === 4)) {
      const m = moment(value).tz(props.timezone);

      if (m.isValid() && props.onChange) {
        props.onChange(value, props);
      } else {
        props.onChange(null, props);
      }
    }
  };

  return (
    <div className="r-date-time">
      <TextInput
        className="r-date-time__text-input"
        autoSelect
        value={state.inputValue}
        onFocus={handleFocus}
        onBlur={handleInputChange}
      />
      <DateTime
        {...props}
        value={state.inputValue}
        input={false}
        displayTimeZone={showTimezones ? props.timezone : undefined}
        timeFormat={momentTimeFormat}
        className={className}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </div>
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  timeFormat: PropTypes.any,
  autoSelect: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isValidDate: PropTypes.func,
  value: PropTypes.any,
  timezone: PropTypes.string.isRequired,
  defaultValue: PropTypes.any
};

export default React.memo(DateTimePicker);
