import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@client/components/Button';
import DialogCard from '@client/components/DialogCard';
import Checkbox from '../Checkbox';

import './style.css';

function shouldDisableConfirmationButton(removeConfirmCheckbox, confirmCheckboxChecked) {
  if (removeConfirmCheckbox) return false;
  return !confirmCheckboxChecked;
}

function ConfirmationDialog(props) {
  const [state, setState] = useState({
    confirmCheckboxChecked: false
  });
  const baseClassName = 'app-confirmation-dialog';
  let className = baseClassName;
  const messageClassName = `${baseClassName}__message`;
  const actionsClassName = `${baseClassName}__actions`;

  if (props.className) {
    className += ` ${props.className}`;
  }

  const handleConfirm = () => {
    if (props.onConfirm) {
      props.onConfirm();
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  const handleCheckboxToggle = () => {
    setState((prevState) => ({
      ...prevState,
      confirmCheckboxChecked: !prevState.confirmCheckboxChecked
    }));
  };

  return (
    <DialogCard onClose={handleCancel} className={className}>
      {props.message && <div className={messageClassName}>{props.message}</div>}
      <div>
        {!props.removeConfirmCheckbox && (
          <Checkbox
            checked={state.confirmCheckboxChecked}
            label={props.label || 'Check here if you have manager approval'}
            onToggle={handleCheckboxToggle}
          />
        )}
        <div className={actionsClassName}>
          <Button label="Cancel" onClick={handleCancel} />
          <Button
            loading={props.isLoading}
            disabled={shouldDisableConfirmationButton(props.removeConfirmCheckbox, state.confirmCheckboxChecked)}
            theme="2"
            label={props.confirmLabel || 'Confirm'}
            onClick={handleConfirm}
          />
        </div>
      </div>
    </DialogCard>
  );
}

ConfirmationDialog.propTypes = {
  className: PropTypes.string,
  confirmLabel: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.any,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  removeConfirmCheckbox: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default ConfirmationDialog;
